import {
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
  getCurrentScope,
} from '@sentry/core';
import TokenService from '../services/token';
import { getCorrelationId } from '../services/correlation';

const setUser = () => {
  const user = TokenService.getUser();
  if (user !== null) getCurrentScope().setUser(user);
};

export const setTags = tags => {
  Object.entries(tags).forEach(([key, value]) => {
    getCurrentScope().setTag(key, value);
  });
};

getCurrentScope().setTag('correlation_id', getCorrelationId());

export const captureException = ({ error, extra = {} }) => {
  setUser();
  if (!(error instanceof Error)) {
    error = error instanceof Object ? new Error(JSON.stringify(error)) : new Error(error);
  }
  sentryCaptureException(error, { extra });
};

export const captureMessage = ({ message, extra = {} }) => {
  setUser();
  sentryCaptureMessage(message, { extra });
};
