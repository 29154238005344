import axios from 'axios';
import cookieStore from './cookie';
import { captureException, captureMessage } from "../plugins/sentry";
import { parseJwt } from './jwt';
import eventBus from '../helpers/eventBus';
import EventNames from '../models/eventNames';
import { getDeviceId } from "../../../../themes/paybis/assets/js/common/nSure";
import axiosRetry from "axios-retry";

const KEYS = {
  JWT: '__jwt',
  JWT_EXPIRATION: '__jwt-exp',
  REFRESH_TOKEN: '__refresh_token',
  REFRESH_TOKEN_EXPIRATION: '__refresh_token-exp',
};

let refreshPromise = null;

export default {
  getToken() {
    return cookieStore.get(KEYS.JWT);
  },

  hasToken() {
    return cookieStore.get(KEYS.JWT) !== undefined;
  },

  getRefreshToken() {
    return cookieStore.get(KEYS.REFRESH_TOKEN);
  },

  hasRefreshToken() {
    return cookieStore.get(KEYS.REFRESH_TOKEN) !== undefined;
  },

  getUserId() {
    const token = this.getToken();
    const { sub } = parseJwt(token);

    return sub;
  },

  getUser() {
    const hasToken = this.hasToken();
    if (hasToken) {
      const token = this.getToken();
      const { sub, email } = parseJwt(token);

      return {
        id: sub,
        email,
      };
    }

    return null;
  },

  setTokens({ jwt, jwtExpiresAt, refreshToken, refreshTokenExpiresAt }) {
    cookieStore.set(KEYS.JWT, jwt, {
      expires: new Date(refreshTokenExpiresAt),
    });
    cookieStore.set(KEYS.REFRESH_TOKEN, refreshToken, {
      expires: new Date(refreshTokenExpiresAt),
    });
    cookieStore.set(KEYS.JWT_EXPIRATION, jwtExpiresAt, {
      expires: new Date(refreshTokenExpiresAt),
    });
    cookieStore.set(KEYS.REFRESH_TOKEN_EXPIRATION, refreshTokenExpiresAt, {
      expires: new Date(refreshTokenExpiresAt),
    });
  },

  removeTokens() {
    cookieStore.remove(KEYS.JWT);
    cookieStore.remove(KEYS.JWT_EXPIRATION);
    cookieStore.remove(KEYS.REFRESH_TOKEN);
    cookieStore.remove(KEYS.REFRESH_TOKEN_EXPIRATION);
  },

  requestNewToken() {
    if (refreshPromise !== null) {
      return refreshPromise;
    }
    if (!this.hasRefreshToken()) {
      eventBus.emit(EventNames.SESSION_TIMEOUT);
      return Promise.reject(new Error('Could not find refreshToken'));
    }

    const instance = axios.create({
      transformRequest: [
        (data, headers) => {
          if (typeof headers === 'object' && headers.common) {
            delete headers.common.Authorization;
          }

          return data;
        },
        ...axios.defaults.transformRequest,
      ],
    });

    axiosRetry(instance, {
      retries: 1,
      retryDelay: retryCount => retryCount * 500,
      retryCondition: err => true // If we got any error we will have 1 try for POST request.
    });

    instance.interceptors.request.use(
      async config => {
        const deviceId = await getDeviceId();

        if (!deviceId) {
          captureMessage({
            message: 'No deviceID found on token refresh',
            extra: {
              deviceId,
              nSureSDK: window.nSureSDK
            }
          });
        }

        config.headers['X-Device-ID'] = deviceId || '';

        return config;
      },
      error => Promise.reject(error),
    );

    refreshPromise = instance.post(
      '/public/authentication-service/v1/jwt/refresh',
      {
        refreshToken: this.getRefreshToken(),
      },
    );

    return refreshPromise
      .then(({ data }) => {
        this.setTokens(data);

        return data;
      })
      .catch(error => {
        const extra = {
          token: this.getToken(),
          refreshToken: this.getRefreshToken(),
          errorExtraMessage: 'Error in refresh method for token',
        };

        captureException({ error, extra });
        this.removeTokens();
        eventBus.emit(EventNames.SESSION_TIMEOUT);

        throw error;
      })
      .finally(() => {
        refreshPromise = null;
      });
  },
};
