import EventBus from 'eventing-bus';

let eventBusInstance;

// In SSR mode we have not window object
if (typeof window !== 'undefined') {
  if (!window.__pb_eventBus) {
    window.__pb_eventBus = EventBus;
  }
  
  eventBusInstance = window.__pb_eventBus;
} else {
  eventBusInstance = EventBus;
}

eventBusInstance.emit = (eventName, payload) => eventBusInstance.publish(eventName, payload);
eventBusInstance.off = (eventName) => eventBusInstance.unregisterCallbacksForEvent(eventName);
eventBusInstance.detach = (eventName, callback) => eventBusInstance.unregisterCallback(callback);

export default eventBusInstance;

